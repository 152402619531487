<template>
  <BadReceiptComponent goods-type="S0100" />
</template>
<script>
import BadReceiptComponent from './BadReceiptComponent'
export default {
  name: 'RegProduct',
  components: {
    BadReceiptComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null
    }
  },
  created () {

  },
  mounted () {
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
